import {User} from "@/models/User.model";
import {DateTime, Interval} from "luxon";
import { Classroom } from "@/models/Classroom.model";

export class Organisation
{
	public id ?: string;
	public name ?: string;
	public locale ?: string;
	public country ?: string;
	public subscription_plan ?: string;
	public account_id ?: string;
	public created_at ?: Date;
	public features : string[] = [];
	public authenticated ?: boolean = false;
	public owner: User | null = null;
	public termination_date ?: string;
	public authenticatedUserRole: string;
	public classrooms ?: Classroom[] = [];
	public active: boolean;
	public curriculum ?: string = null;
	public agenda_settings : {
		include_weekends: boolean,
		school_hours_start: string,
		school_hours_end: string,
	} | null

	public hasFeature(feature: string) {
		return this.features.indexOf(feature) >= 0;
	}

	static mapFromServer(data: any) {
		const organisation = new Organisation();
		organisation.setFromServerData(data);

		return organisation;
	}

	setFromServerData(data: any)
	{
		this.id = data.id;
		this.name = data.name;
		this.locale = data.locale;
		this.country = data.country;
		this.subscription_plan = data.subscription_plan;
		this.account_id = data.account_id;
		this.created_at = new Date(data.created_at);
		this.authenticated = data.authenticated;
		this.active = data.active;

		if (data.termination_date) {
			this.termination_date = DateTime.fromISO(data.termination_date).toFormat('yyyy-MM-dd');
		} else {
			this.termination_date = null;
		}

		if (data.features) {
			this.features = data.features;
		}

		if (data.owner) {
			this.owner = User.mapFromServer(data.owner);
		}

		if (data.user_role) {
			this.authenticatedUserRole = data.user_role;
		}

		if (data.classrooms) {
			this.classrooms = data.classrooms.map(Classroom.mapFromServer);
			this.classrooms.forEach(classroom => {
				classroom.organisation = this;
			});
		}

		if (data.curriculum) {
			this.curriculum = data.curriculum;
		}

		if (data.agenda_settings) {
			this.agenda_settings = data.agenda_settings;
		}
	}

	getServerData() {
		const out = {
			name: this.name,
			locale: this.locale,
			country: this.country,
			subscription_plan: this.subscription_plan,
			account_id: this.account_id,
			termination_date: this.termination_date,
			curriculum: this.curriculum,
			agenda_settings: this.agenda_settings,
		};

		return out;
	}

	clone(): Organisation {
		let clonedInstance = Object.assign(
			Object.create(
				Object.getPrototypeOf(this)
			),
			this
		);
		return clonedInstance;
	}

	hasId() {
		return this.id !== undefined;
	}

	isPaying() {
		return this.subscription_plan.substring(0, 6) === 'paying';
	}
}
